import React from 'react'
import styled from 'styled-components'

export const VideoSection = () => {
  return (
    <StyledSection>
      {/*  <video autoPlay muted loop playsInline className="video">
        <source src={video} type="video/mp4" />
      </video> */}

      <div className="iframe-wrapper">
        <iframe
          src="https://www.youtube.com/embed/rgAtCfLzPl0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </StyledSection>
  )
}

export const StyledSection = styled.section`
  padding-top: ${({ theme }) => theme.spacingXL};
  padding-bottom: ${({ theme }) => theme.spacingS};
  text-align: center;

  video {
    max-width: 100%;
    width: auto;
    height: auto;
  }

  .iframe-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`
