import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { MainSectionGreen } from '../components/MainSectionGreen'
import { FirstSectionGreen } from '../components/FirstSectionGreen'
import { VideoSection } from '../components/VideoSection'
import { PlantasSection } from '../components/PlantasSection'
import { CampusSection } from '../components/CampusSection'
import { FormSection } from '../components/FormSection'
import { Styled } from '../styles/green.styles'
import { graphql } from 'gatsby'

const GreenPage = () => {
  return (
    <Layout>
      <SEO
        title="Edifício Green"
        description="Um ótimo investimento que tem o objeto de abraçar o universo dinâmico da vida acadêmica."
      />
      <Styled.Main>
        <MainSectionGreen />
        <FirstSectionGreen />
        <PlantasSection />
        <VideoSection />
        <CampusSection />
        <FormSection />
      </Styled.Main>
    </Layout>
  )
}

export default GreenPage

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["home", "green", "global"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
