import React, { useState, useEffect } from 'react'
import Bubble from '../assets/images/bubble.svg'
import SmallArrow from '../assets/images/smallarrow.svg'
import PDF from '../assets/green.pdf'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const PlantasSection = () => {
  const { t } = useTranslation()
  const content = t('plantas', { returnObjects: true })

  const [activePlant, setActivePlant] = useState(content.gallery[0])
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => setCurrentImage(0), [activePlant])

  return (
    <StyledSection className="first-section" id="edificio">
      <div className="content">
        <div className="left">
          <div className="title-wrapper">
            <Bubble />
            <h1
              className="title"
              dangerouslySetInnerHTML={{ __html: content.title }}
            />
          </div>

          <p className="text">{content.text}</p>
        </div>

        <div className="right">
          <div className="active-image">
            <img
              src={activePlant.images[currentImage]}
              alt="Plant Humanizada"
            />
            {activePlant.images[currentImage - 1] && (
              <SmallArrow
                className="small-arrow"
                onClick={() => setCurrentImage(currentImage - 1)}
              />
            )}
            {activePlant.images[currentImage + 1] && (
              <SmallArrow
                className="small-arrow inverted"
                onClick={() => setCurrentImage(currentImage + 1)}
              />
            )}
          </div>

          <div className="plant-types">
            {content.gallery.map((plant) => (
              <p
                className={plant.id === activePlant.id ? 'selected' : ''}
                onClick={() => setActivePlant(plant)}
                key={plant.id}
              >
                {plant.title}
              </p>
            ))}
          </div>

          <p className="text">{content.text}</p>
        </div>
      </div>

      <a href={PDF} className="download-btn">
        <button>{content.btn}</button>
      </a>
    </StyledSection>
  )
}

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  padding-top: ${({ theme }) => `${theme.spacingXL}`};
  padding-bottom: ${({ theme }) => `${theme.spacingXL}`};

  .content {
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacingL};
  }

  .left {
    width: 35%;
  }
  .right {
    width: 65%;
  }

  .right .text {
    display: none;
  }

  .active-image {
    position: relative;
    text-align: center;
    margin: 0 auto;

    img {
      max-width: 100%;
      max-height: 60rem;
    }

    .small-arrow {
      position: absolute;
      top: 50%;
      left: -5rem;
      transform: translateY(-50%);
      cursor: pointer;

      &.inverted {
        left: unset;
        right: -5rem;
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .plant-types {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacingXL};

    p {
      font-size: 2rem;
      line-height: 1.2rem;
      text-transform: uppercase;
      color: ${({ theme }) => theme.green};
      margin: 0 ${({ theme }) => theme.spacingS};
      cursor: pointer;

      &.selected {
        color: ${({ theme }) => theme.white};
      }
    }
  }

  .title-wrapper {
    position: relative;

    svg {
      position: absolute;
      top: -2.5rem;
      left: -5.5rem;
    }
  }

  .text {
    margin-top: ${({ theme }) => theme.spacingM};
  }

  .download-btn {
    width: 100%;
    text-align: center;
    display: block;
    margin-top: ${({ theme }) => theme.spacingL};

    button {
      font-size: 1.8rem;
      letter-spacing: 0.03rem;
      text-transform: uppercase;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0));
      border: 1px solid #48a67f;
      padding: 0.5rem 3rem;
      border-radius: 25rem;
      transition: all 1s ease;
      cursor: pointer;

      &:hover {
        background-image: linear-gradient(to right, #48a67f, #3e418b, #d80a1e);
        border: 1px solid transparent;
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .content {
      flex-direction: column;
      text-align: center;
    }

    .left,
    .right {
      width: 100%;
    }

    .title-wrapper {
      display: inline-block;
      text-align: left;
    }

    .right .text {
      display: block;
    }

    .left .text {
      display: none;
    }
  }

  @media screen and (max-width: 1000px) {
    padding-top: ${({ theme }) => theme.spacingS};

    .title-wrapper {
      display: inline-block;

      svg {
        top: -3rem;
        left: -7rem;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .small-arrow {
      height: 2rem;
      left: -8rem !important;

      &.inverted {
        left: unset !important;
        right: -8rem !important;
      }
    }

    .plant-types {
      flex-wrap: wrap;
      gap: 3rem;
    }
  }

  @media screen and (max-width: 500px) {
    .active-image {
      min-height: 35rem;
    }
  }

  @media screen and (max-width: 380px) {
    .active-image {
      min-height: 25rem;
    }

    .title-wrapper {
      svg {
        height: 5rem;
        width: auto;
        top: -2rem;
        left: -5rem;
      }
    }
  }
`
