import React from 'react'
import { LanguageSwitcher } from './LanguageSwitcher'
import { SocialIcons } from './SocialIcons'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const MainSectionGreen = () => {
  const { t } = useTranslation()
  const content = t('intro', { returnObjects: true })

  return (
    <StyledSection
      className="main-section"
      style={{ backgroundImage: `url(${content.background})` }}
    >
      <img
        src={content.img}
        alt="Edifício Green"
        className="green__image desktop"
      />
      <img
        src={content.mobileImg}
        alt="Edifício Green"
        className="green__image mbl"
      />

      <LanguageSwitcher />

      <SocialIcons isColumn />

      <img src={content.shape1} className="shape shape-main-1" />
      <img src={content.shape2} className="shape shape-main-2" />
    </StyledSection>
  )
}

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: flex-end;
  padding: ${({ theme }) => theme.spacingXL};
  background-size: cover;
  background-position: center;

  .green__image {
    margin: 0 auto;
    transform: translateY(10rem);
    z-index: 11;
  }

  .language-switcher {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${({ theme }) => theme.spacingS};
  }

  .social-icons {
    position: absolute;
    bottom: ${({ theme }) => theme.spacingS};
    right: ${({ theme }) => theme.spacingS};
  }

  .shape-main-1 {
    width: 30rem;
    bottom: -30rem;
    left: -10rem;
    transform: rotate(-35deg);
  }
  .shape-main-2 {
    width: 75rem;
    bottom: -15rem;
    right: 25rem;
  }

  @media screen and (max-width: 1500px) {
    align-items: center;
    padding-right: ${({ theme }) => theme.spacingM};
    padding-left: ${({ theme }) => theme.spacingM};
  }

  @media screen and (max-width: 1000px) {
    min-height: 50vh;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;

    .social-icons {
      display: none;
    }

    .shape-main-1 {
      width: 15rem;
      bottom: -20rem;
      left: -5rem;
    }
    .shape-main-2 {
      width: 40rem;
      bottom: 0;
      right: -5rem;
    }
  }

  @media screen and (max-width: 550px) {
    .language-switcher {
      top: unset;
      bottom: 5rem;
      transform: none;
    }
  }
`
