import React, { useState } from 'react'
import Picker from '../assets/images/picker.svg'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const CampusSection = ({ withBackground }) => {
  const [selectedBlueprint, setSelectedBlueprint] = useState(null)

  const { t } = useTranslation()
  const content = t('campus', { returnObjects: true })

  return (
    <StyledSection
      className="campus-section"
      id="localizacao"
      style={{
        backgroundImage: withBackground
          ? `url(${content.background})`
          : 'black',
      }}
    >
      <div className="content">
        <div className="left">
          <div className="text-with-icon">
            <div className="icon">
              <Picker />
            </div>
            <h1
              className="title"
              dangerouslySetInnerHTML={{ __html: content.title }}
            />
          </div>

          <p className="text">{content.text}</p>
        </div>

        <div className="blueprint">
          <img
            src={
              selectedBlueprint
                ? selectedBlueprint.img
                : content.defaultBluePrint
            }
          />
          <div className="options">
            {content.bluePrintOptions &&
              content.bluePrintOptions.length > 0 &&
              content.bluePrintOptions.map((option) => (
                <span
                  key={option.id}
                  onClick={() => setSelectedBlueprint(option)}
                  className={
                    selectedBlueprint && selectedBlueprint.id === option.id
                      ? 'selected'
                      : ''
                  }
                >
                  {option.title}
                </span>
              ))}
          </div>
        </div>
      </div>
    </StyledSection>
  )
}

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  padding-top: ${({ theme }) => `${theme.spacingXL}`};
  padding-bottom: ${({ theme }) => `${theme.spacingL}`};

  .content {
    display: flex;
    align-items: center;
  }

  .left {
    width: 40%;
  }

  .text {
    margin-top: ${({ theme }) => theme.spacingM};
  }

  .blueprint {
    width: 60%;

    .options {
      display: flex;
      justify-content: center;
      margin-top: ${({ theme }) => theme.spacingM};

      span {
        color: ${({ theme }) => theme.green};
        font-size: 2rem;
        line-height: 2.6rem;
        letter-spacing: 0.12rem;
        text-transform: uppercase;
        margin: 0 ${({ theme }) => theme.spacingXS};
        cursor: pointer;
        transition: color 0.25s ease;

        &:hover,
        &.selected {
          color: ${({ theme }) => theme.white};
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    background-image: none !important;
    background: ${({ theme }) => theme.black};

    .content {
      flex-direction: column;
    }

    .left,
    .blueprint {
      width: 100%;
    }

    .blueprint {
      margin-top: ${({ theme }) => theme.spacingL};

      .options {
        flex-direction: column;
        align-items: center;

        span {
          margin: ${({ theme }) => theme.spacingXS} 0;
        }
      }
    }
  }
`
