import React, { useState } from 'react'
import { CTA } from './CTA'
import Oven from '../assets/images/oven.svg'
import Desk from '../assets/images/desk.svg'
import Bed from '../assets/images/bed.svg'
import Shower from '../assets/images/shower.svg'
import Bubble from '../assets/images/bubble.svg'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Link } from 'gatsby'

export const FirstSectionGreen = () => {
  const [activeImage, setActiveImage] = useState(0)

  const { t } = useTranslation()
  const content = t('firstSection', { returnObjects: true })

  return (
    <StyledSection className="first-section" id="edificio">
      <div className="content">
        <div className="left">
          <div
            className="active-image"
            style={{ backgroundImage: `url(${content.images[activeImage]})` }}
          />

          <div className="icons">
            <div
              onClick={() => setActiveImage(0)}
              className={activeImage === 0 ? 'active icon' : 'icon'}
            >
              <Oven />
            </div>
            <div
              onClick={() => setActiveImage(1)}
              className={activeImage === 1 ? 'active icon' : 'icon'}
            >
              <Desk />
            </div>
            <div
              onClick={() => setActiveImage(2)}
              className={activeImage === 2 ? 'active icon' : 'icon'}
            >
              <Bed />
            </div>
            <div
              onClick={() => setActiveImage(3)}
              className={activeImage === 3 ? 'active icon' : 'icon'}
            >
              <Shower />
            </div>
          </div>
        </div>

        <div className="right">
          <div className="title-wrapper">
            <Bubble />
            <h1
              className="title"
              dangerouslySetInnerHTML={{ __html: content.title }}
            />
          </div>

          <p className="text">{content.text}</p>

          <Link to={content.ctaUrl}>
            <CTA text={content.cta} greenBorder />
          </Link>
        </div>
      </div>

      <img src={content.shape} className="shape shape-1" />
    </StyledSection>
  )
}

export const StyledSection = styled.section`
  position: relative;
  width: 100%;
  padding-top: ${({ theme }) => `${theme.spacingXL}`};
  padding-bottom: ${({ theme }) => `${theme.spacingXL}`};

  .content {
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacingL};
  }

  .left {
    width: 65%;
  }
  .right {
    width: 35%;
  }

  .active-image {
    width: 100%;
    min-height: 55rem;
    background-size: cover;
    background-position: center;
  }

  .icons {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: ${({ theme }) => theme.spacingS};
    margin-top: ${({ theme }) => theme.spacingS};

    .icon {
      cursor: pointer;

      &:nth-child(2) {
        margin-right: ${({ theme }) => theme.spacingXS};
      }

      svg * {
        transition: all 0.3s ease;
      }

      &.active svg * {
        stroke: ${({ theme }) => theme.white};
      }
    }
  }

  .title-wrapper {
    position: relative;

    svg {
      position: absolute;
      top: -2.5rem;
      left: -5.5rem;
    }
  }

  .text,
  .text-with-bubble,
  .cta {
    margin-top: ${({ theme }) => theme.spacingM};
  }

  .shape-1 {
    transform: rotate(-65deg);
    right: -12rem;
    z-index: 11;
  }

  @media screen and (max-width: 1000px) {
    padding-top: ${({ theme }) => theme.spacingS};

    .content {
      flex-direction: column;
      text-align: center;
    }

    .left,
    .right {
      width: 100%;
    }

    .active-image {
      min-height: 45rem;
    }

    .title-wrapper {
      display: inline-block;

      svg {
        top: -3rem;
        left: -7rem;
      }
    }

    .text-with-bubble {
      justify-content: center;
    }

    .shape-1 {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    .active-image {
      min-height: 35rem;
    }

    .icons svg {
      height: 7rem;
      width: auto;
    }
  }

  @media screen and (max-width: 380px) {
    .active-image {
      min-height: 25rem;
    }

    .icons svg {
      height: 5rem;
      width: auto;
    }

    .title-wrapper {
      svg {
        height: 5rem;
        width: auto;
        top: -2rem;
        left: -5rem;
      }
    }
  }
`
