import styled from 'styled-components'

const Main = styled.main`
  position: relative;
  width: 100%;
`

export const Styled = {
  Main,
}
